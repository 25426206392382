import { useRouter } from 'next/router'
import Image from 'next/image'
import { useGetVoteChanceByCodeQuery } from 'services/campaign'
import { getImage, getSharePageLink, getUnifiedLink, showToast } from 'shared/utils'
import { isPC, micrositeLogin, micrositeNavigate } from 'shared/webBridge'
import { IAxiosBaseQueryError } from 'types'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store'
import { setError, setIsLogin } from 'domains/Home/slice'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

const VotePanel = () => {
  const isLogin = useSelector((state: RootState) => state.home.isLogin)
  const dispatch = useAppDispatch()
  const router = useRouter()
  const { query, isReady } = router
  const { data, isLoading, error, refetch } = useGetVoteChanceByCodeQuery(query.cid as string, {
    skip: !isLogin || !isReady,
    refetchOnFocus: true,
    refetchOnReconnect: true,
  })
  const { t } = useTranslation()

  const content = useMemo(() => {
    if (isLoading) return null

    const { total_balance: totalBalance, remaining_share_times: remainingShareTimes } = data || {}

    const handleGetMoreVote = () => {
      if (!isPC()) {
        micrositeNavigate(getUnifiedLink(getSharePageLink('campaign')))
      } else {
        showToast({ message: t('Tính năng này không được hỗ trợ trên thiết bị của bạn'), status: 'failure' })
      }
    }

    return (
      <div className="flex items-center text-xs large:text-base pr-4 ">
        {isLogin ? (
          <>
            <p>{t('Bạn còn {{count}} lượt bình chọn.', { count: totalBalance || 0 })}</p>
            &nbsp;
            <button
              type="button"
              id="get-more-vote-btn"
              className="text-highlight font-medium py-1.5 disabled:text-gray-500 focus:outline-none disabled:hidden"
              onClick={handleGetMoreVote}
              disabled={remainingShareTimes === 0}
            >
              {t('Thêm lượt')}
            </button>
          </>
        ) : (
          <>
            <p>{t('Đăng nhập để bình chọn.')}</p>
            &nbsp;
            <button
              type="button"
              id="login-btn"
              className="text-highlight font-medium py-1.5 focus:outline-none"
              onClick={() => micrositeLogin(query.site as string, refetch)}
            >
              {t('Đăng nhập ngay')}
            </button>
          </>
        )}
      </div>
    )
  }, [isLogin, isLoading, data])

  useEffect(() => {
    if (!error) return

    const { data } = error as IAxiosBaseQueryError
    if (data?.code === 4007) dispatch(setIsLogin(false))
    else dispatch(setError(error))
  }, [error])

  return (
    <div className="flex justify-center py-4 large:py-7" id="turn-panel-container">
      <div className="flex items-center rounded-full w-fit border-gradient large:h-12.5" id="vote-chance-panel">
        <div className="flex -m-px mr-3 large:mr-6 w-7.5 h-7.5 large:w-12.5 large:h-12.5 relative">
          <Image src={getImage('heart-icon.png')} draggable={false} layout="fill" alt="" />
        </div>
        {content}
      </div>
    </div>
  )
}

export default VotePanel
